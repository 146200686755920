










































































import { DespachoReporteResponse,ObtenerDespachoFecha,ObtenerDespachoHistorico,ObtenerPalletDespacho } from '@/entidades/KTB/Despacho/Despacho';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component} from 'vue-property-decorator';
import { Action } from 'vuex-class';
import moment from 'moment';
import PalletSend from '@/entidades/KTB/Consulta/PalletSend';
@Component({
    name : 'DespachoVerde',
    components:{
        'tabla' : () => import('@/components/General/TablaGeneralFinal.vue'),
    }
})
export default class DespachoVerde extends Vue
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    componentName:string="";
    componenteOpen:number = 1;
    componentModel:boolean=false;
    menu:boolean =false;
    menu2:boolean =false;
    desde:string ="";
    hasta:string ="";
    listado:Array<DespachoReporteResponse> =[];
    listadoHistorico:Array<DespachoReporteResponse> =[];
    listadoHistoricoPallet:Array<PalletSend> =[];
    //#region Variables Tabla
    header:{text:string,sortable:boolean,value:string}[] =
    [
        {text:"Despacho",sortable:true,value:'despachoId'},
        {text:"Planta",sortable:false,value:'plantaName'},
        {text:"Fecha",sortable:true,value:'fecha'},
        {text:"Estado",sortable:true,value:'estadoProceso'}, 
        {text:"Action",sortable:true,value:'actions'},                    
    ];
     headerHistorico:{text:string,sortable:boolean,value:string}[] =
    [
        {text:"Fecha",sortable:true,value:'fecha'},
        {text:"Descripcion",sortable:true,value:'descripcion'}                  
    ];
    headerHistoricoPallet:{text:string,sortable:boolean,value:string}[] =
    [
        {text:"# Pallet",sortable:true,value:'palletId'},
        {text:"Espesor",sortable:true,value:'espesor'},
        {text:"Largo",sortable:true,value:'largo'},
        {text:"Plantilla",sortable:true,value:'plantillaTotal'},  
        {text:"Estado",sortable:true,value:'estadoProceso'},                   
    ];
    //#endregion

    //#region  Eventos locales
     closeDesde(fecha:string)
     {
        this.menu = false;
        this.desde = fecha;
     
     }
     closeHasta(fecha:string)
     {
        this.hasta = fecha;
        this.menu2 = false;
     }
    //#endregion

    //#region Conexion el API
    async ObtenerDespacho()
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion"));
            
            var response = await ObtenerDespachoFecha(this.desde,this.hasta);
            if(response.isSuccess)
            {
                this.listado = response.isResult;
                return;
            }
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    async OpenHistory(item:DespachoReporteResponse)
    {
        this.componenteOpen = 1;
        try 
        {   
            this.changeLoading(new Loading(true,"Obteniendo datos historico"));
            var response = await ObtenerDespachoHistorico(item.despachoId);
            if(response.isSuccess)
            {
                this.listadoHistorico = response.isResult;
                this.componentName = "tabla";
                this.componentModel = true;
                return;
            }
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    async OpenPallet(item:DespachoReporteResponse)
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion"));
            let response = await ObtenerPalletDespacho(item.despachoId);
            if(response.isSuccess)
            {
                this.listadoHistoricoPallet = response.isResult;
                this.componenteOpen = 2;
                this.componentName = "tabla";
                this.componentModel = true;
                return;
            }
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    //#endregion
    mounted() 
    {
        this.desde = moment(new Date()).format("YYYY-MM-DD");
        this.hasta = moment(new Date()).format("YYYY-MM-DD");
    }

    //#region Computed
    get componentProperties()
    {
        if(this.componenteOpen == 1)
        {
            return {'headers' : this.headerHistorico, 'items' : this.listadoHistorico,'busqueda':false};
        }
        else if(this.componenteOpen == 2)
        {
            return {'headers' : this.headerHistoricoPallet, 'items' : this.listadoHistoricoPallet,'busqueda':false};
        }
    }
    //#endregion
}
